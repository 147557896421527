import { Container } from "react-bootstrap"
export default function NotFound(){
    return(
        <Container fluid>
            <div style={{'display':'flex', 'alignItems':'center'}}>
                <img width="40%" src="/images/404.svg" />
                <div >
                    <h1>Aww!</h1>
                    <p>Looks like that page is not found! How about browsing from the menu on the left?</p> 
                </div>
            </div>
                      
            
        </Container>
    )
}
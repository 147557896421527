import { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { Container, Row, Col, Card, Spinner} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faBrain, faHandshakeAngle } from "@fortawesome/free-solid-svg-icons";
import { fetchData } from "./commonFunctions";

export default function Dashboard({data, setData}){
    
    const [loading, setLoading] = useState(true)
    useEffect(()=>{fetchData("homeitems", data, setData, setLoading)})
    function getNumItems(type){
        let items = []
        data.forEach((it)=>{if (it.type===type){items.push(it)}})
        return(items.length)
    }
    function cardify(statistic,icon, description, key){
        return(
            <Card key={key} style={{'width': '10em', 'margin':'10px'}}>
                <Card.Header style={{'fontSize':'30px', 'fontWeight':'bold', 'textAlign':'center'}}>
                    {statistic}
                </Card.Header>
                <Card.Body style={{'background':'rgb(241 178 178 / 31%)', 'textAlign':'center'}}>
                <div style={{'display':'flex','fontSize':'18px', 'justifyContent':'center', 'fontFamily':'Franklin Gothic Medium',}}>
                    <span style={{'marginRight':'5px'}}>{icon}</span>
                    <span>{description}</span>
                    </div>                    
                </Card.Body>
            </Card>
        )
    }
    return(
        <>
        {loading?<Spinner />:
        <Container fluid>
            <Row>
                <Col>
                    <Link style={{'color':'inherit', 'textDecoration':'none'}} to="/projects">
                    {cardify(getNumItems("project"),<Icon.BriefcaseFill /> , "Projects", "projects")}
                    </Link>
                </Col>
                <Col>
                    <Link style={{'color':'inherit', 'textDecoration':'none'}} to="/articles">
                        {cardify(getNumItems("article"),<Icon.PenFill /> , "Articles", "articles")}
                    </Link>
                </Col>
                <Col>
                    <Link style={{'color':'inherit', 'textDecoration':'none'}} to="/certifications">
                    {cardify(getNumItems("certificate"),<FontAwesomeIcon icon={faGraduationCap} /> , "Certifications", "certifications")}
                    </Link>
                </Col>
                <Col>
                    <Link style={{'color':'inherit', 'textDecoration':'none'}} to="/clinicalExperience">
                    {cardify(getNumItems("clinicalExperience"),<FontAwesomeIcon icon={faBrain} /> , "Clinical Experience", "ClinicalExperience")}
                    </Link>
                </Col>
            
                <Col>
                    <Link style={{'color':'inherit', 'textDecoration':'none'}} to="/volunteerExperience">
                    {cardify(getNumItems("volunteerExperience"),<FontAwesomeIcon icon={faHandshakeAngle} /> , "Volunteer Experience", "volunteerExperience")}
                    </Link>
                </Col>
                <Col>
                    <Link style={{'color':'inherit', 'textDecoration':'none'}} to="/updates">
                    {cardify(getNumItems("update"),<Icon.Newspaper /> , "Updates", "updates")}
                    </Link>
                </Col>
                <Col>
                    <Link style={{'color':'inherit', 'textDecoration':'none'}} to="/certifications">                
                    {cardify("242", <Icon.AwardFill />, "Step-1 score", "step1")}
                    </Link>
                </Col>
                <Col>
                    <Link style={{'color':'inherit', 'textDecoration':'none'}} to="/certifications">                
                    {cardify("249", <Icon.AwardFill />, "Step-2 score", "step2")}
                    </Link>
                </Col>
            </Row>
        </Container>}
        </>
    )
}
import { Accordion } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons"

export function fetchData(type, data, setData, setLoading){        
    if (data==null){
    fetch(process.env.REACT_APP_BACKEND_URL.concat('/db/get').concat(type), {method:'GET',mode:"cors"})
    .then((response)=>response.json())
    .then((dataR)=>{setData(dataR.sort((a,b)=>(Date.parse(b.date)-Date.parse(a.date))));setLoading(false);})}
    else{setLoading(false)}
}

export function accordify(featured, heading, content, evkey){
    return(
        
            <Accordion.Item eventKey={evkey} key={evkey}>
                <Accordion.Header>
                    <div style={{'fontSize':'19px'}}>
                {featured?<span><Icon.StarFill style={{'color':'gold'}} /></span>:null}
                    {heading}
                    </div>
                </Accordion.Header>
                <Accordion.Body dangerouslySetInnerHTML={{__html:content}}></Accordion.Body>
            </Accordion.Item>
        
    )
}
export function accordify3(heading, content, evkey){
    return(
        
            <Accordion.Item eventKey={evkey} key={evkey}>
                <Accordion.Header>
                <div style={{'fontSize':'19px'}}>
                    {heading}
                    </div>
                </Accordion.Header>
                <Accordion.Body dangerouslySetInnerHTML={{__html:content}}></Accordion.Body>
            </Accordion.Item>
        
    )
}
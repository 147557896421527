import { useState, useEffect } from "react";
import * as Icon from 'react-bootstrap-icons'
import './homeItems.css'
import {Container, Row, Col, Card, ListGroup, Spinner, Badge} from 'react-bootstrap'
import { fetchData } from "./commonFunctions";
import { Link } from "react-router-dom";

export default function HomeItems({data, setData}){
    const [dataLoading, setDataLoading] = useState(true)
    useEffect(()=>{fetchData("homeitems", data, setData, setDataLoading)},[data])

    function cardify(heading, itemList, link){         
        return(
            <Card key={heading} style={{"width":"15rem", margin:'10px'}}>
                
                    <Card.Header 
                    style={{'backgroundColor':'rgb(241 178 178 / 31%)', 
                    'display':'flex', 
                    justifyContent:'space-between', fontSize:'20px'                    
                    }}>
                        <div style={{fontFamily:'Franklin Gothic Medium'}}>{heading}</div>
                        <Badge style={{'height':'30px'}} bg="primary" ><Link style={{'textDecoration':'none', 'color':'inherit', }} to={link}>open</Link></Badge>
                    </Card.Header>
                    <ListGroup variant="flush">
                        {itemList.map((item, i)=>(<ListGroup.Item key={i}><Link style={{'textDecoration':'none', 'color':'inherit'}} to={link}>{item}</Link></ListGroup.Item>))}
                    </ListGroup>
                
            </Card>
        )      
    }

    
     
    

    function getItems(type){
        let items = []
        data.forEach((it)=>{if (it.type===type){items.push(it)}})        
        items.sort((a,b)=>(Date.parse(b.date)-Date.parse(a.date)))
        items.length = 5        
        return (items.map((it)=>(it.title)).filter(Boolean))
    }

    

    function cardifyFeatured(){
        let items = []
        data.forEach((it)=>{if (it.featured){items.push(it)}})
        items.sort((a,b)=>(Date.parse(b.date)-Date.parse(a.date)))
        items.length = 5
        return(
            <Card key="featured" style={{"width":"15rem", margin:'10px'}}>
                
                    <Card.Header 
                    style={{'backgroundColor':'rgb(241 178 178 / 31%)', 
                    'display':'flex', 
                    justifyContent:'flex-start', fontSize:'20px', 'fontWeight':'bold'                    
                    }}>
                        <span><Icon.StarFill style={{'color':'gold'}} /></span>
                        <div style={{fontFamily:'Franklin Gothic Medium'}}>Featured</div>
                        
                    </Card.Header>
                    <ListGroup variant="flush">
                        {items.map((item, i)=>(<ListGroup.Item key={i}><Link style={{'textDecoration':'none', 'color':'inherit'}} to={item.type==="article"?"/articles":item.type==="certificate"?"/certifications":item.type==="project"?"/projects":item.type==="update"?"/updates":null} >{item.title}</Link></ListGroup.Item>))}
                    </ListGroup>
                
            </Card>
        )
    }

    return(

        <Container fluid>            
                <Row>
                    <div className="websiteIntro">
                        Hi, welcome to my website. This website showcases various aspects of my work. I designed this website with clutter-free and highly organized elements. Feel free to navigate through each page
                        on the left and click on the elements in each page to expand them and know more.
                    </div>
                </Row>
                {dataLoading?<Spinner />:
                <>

                <Row>
                    <Col>
                    {cardifyFeatured()}
                    </Col>
                        
                    <Col>
                    {cardify("Projects", getItems("project"), "/projects")}
                    </Col>
                    <Col>
                    {cardify("Articles", getItems("article"), "/articles")}
                    </Col>
                    <Col>
                    {cardify("Certifications", getItems("certificate"), "/certifications")}
                    </Col>
                    <Col>
                    {cardify("Clinical Experience", getItems("clinicalExperience"), "/clinicalExperience")}
                    </Col>
                    <Col>
                    {cardify("Volunteer Experience", getItems("volunteerExperience"), "/volunteerExperience")}
                    </Col>
                    <Col>
                    {cardify("Updates", getItems("update"), "/updates")}
                    </Col>

                    
                    
                    <Col>
                        
                        <Card key="Dashboard" style={{"width":"15rem",  margin:'10px'}}>
                            
                            <Card.Body style={{'backgroundColor':'rgb(241 178 178 / 31%)'}}>
                                <Card.Title style={{'display':'flex', 'justifyContent':'space-between',fontSize:'20px', 'fontWeight':'bold'}}>Dashboard<span><Badge bg="primary"><Link style={{'textDecoration':'none', 'color':'inherit'}} to="/dashboard">open</Link></Badge></span></Card.Title>
                                <Card.Text>statistical overview of my work</Card.Text>
                                
                            </Card.Body>
                        </Card>
                        
                    </Col>
                    <Col>
                        
                        <Card key="About" style={{"width":"15rem", margin:'10px'}}>
                            
                            <Card.Body style={{'backgroundColor':'rgb(241 178 178 / 31%)'}}>
                                <Card.Title style={{'display':'flex', 'justifyContent':'space-between',fontSize:'20px', 'fontWeight':'bold'}}>About Me<span><Badge bg="primary"><Link style={{'textDecoration':'none', 'color':'inherit'}} to="/about">open</Link></Badge></span></Card.Title>
                                <Card.Text>personal insight!</Card.Text>
                                
                            </Card.Body>
                        </Card>
                        
                    </Col>
                    
                </Row>
                </>}
        </Container>
    )
}
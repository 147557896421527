import { useState, useEffect } from "react";
import './home.css'
import * as Icon from 'react-bootstrap-icons'
import {useLocation,Link} from "react-router-dom";
import {Outlet, NavLink} from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStethoscope, faGraduationCap, faUserDoctor, faBrain, faHandshakeAngle } from "@fortawesome/free-solid-svg-icons";

export default function Home(){
    const [jumboRendered, setJumboRendered] = useState(false)
    
    const [navActive, setNavActive] = useState(false)
    const [ecgBigIntervalId, setEcgBigIntervalID] = useState()
    const [ecgSmallIntervalID, setEcgSmallIntervalID] = useState()
    const location = useLocation()
   
    useEffect(
        ()=>{
        document.getElementsByClassName("welcome")[0].style.opacity = 1   
        const timeout = setTimeout(()=>{
            document.getElementsByClassName("welcome")[0].style.opacity = 0
            setJumboRendered(true)
            
        }, 1500)
        return ()=>clearTimeout(timeout)
        }, []
    )
    useEffect(
        ()=>{
            var intervalBig;
            if (navActive){
                console.log(navActive)
                console.log("setting Interval")
                intervalBig = setInterval(()=>{
                    if (document.getElementById("ecg").className==='ecgBigInit'){
                        document.getElementById("ecg").className='ecgBigEnter'
                    }
                    else if (document.getElementById("ecg").className==='ecgBigEnter'){
                        document.getElementById("ecg").className='ecgBigExit'
                    }
                    else{document.getElementById("ecg").className='ecgBigInit'}
                }, 1000)
                setEcgBigIntervalID(intervalBig)
            }
            else {
                try{clearInterval(ecgBigIntervalId)}
                catch{console.log("error clearing interval")}
                
            }
        }, [navActive]
    )
    useEffect(
        ()=>{
            var intervalSmall;
            if (!navActive){
                
                intervalSmall = setInterval(()=>{
                    if (document.getElementById("ecg").className==='ecgSmallInit'){
                        document.getElementById("ecg").className='ecgSmallEnter'
                    }
                    else if (document.getElementById("ecg").className==='ecgSmallEnter'){
                        document.getElementById("ecg").className='ecgSmallExit'
                    }
                    else{document.getElementById("ecg").className='ecgSmallInit'}
                }, 1000)
                setEcgSmallIntervalID(intervalSmall)
            }
            else {
                try{clearInterval(ecgSmallIntervalID)}
                catch{console.log("error clearing interval")}
                
            }
        }, [navActive]
    )
    const Welcome =  
        <div key="welcome" className="welcome">
            <span>
                <Icon.HeartPulse></Icon.HeartPulse>
            </span>     
            <div key="hi" className="greet">Hi</div>       
        </div>
    
    const styleLinkActive = {'display':'flex', 'justifyContent':'flex-start','width':'inherit', 'textDecoration':'none', 'color':'inherit', backgroundColor:'brown'}
    const styleLink = {'display':'flex', 'justifyContent':'flex-start', 'textDecoration':'none', 'color':'inherit'}
    
    const Main = 
        <>
            <div key="sidenav" className={navActive?"sideNavWide":"sideNav"}>
                <div key="ecgmenu" style={navActive?{'display':'flex', 'flexDirection':'row'}:{'display':'flex', 'flexDirection':'column'}}>
                    <div style={{'overflowX':'hidden'}}><span id="ecg" className={navActive?"ecgBigInit":"ecgSmall"}><Icon.Activity style={{'minWidth':'1em'}} /></span></div>
                    <div onClick={()=>{setNavActive(!navActive)}} className="menuFlex"><Icon.List className="menuIcon"/></div>
                </div>    
                <ul>
                    <li key="home"><NavLink style={({isActive})=>isActive?styleLinkActive:styleLink} to="/"><span className="icon"><Icon.HouseDoorFill  /></span><span className="navTitle">Home</span></NavLink></li>
                    <li key="dash"><NavLink style={({isActive})=>isActive?styleLinkActive:styleLink} to="/dashboard"><span className="icon"><Icon.Speedometer  /></span><span className="navTitle">Dashboard</span></NavLink></li>
                    <li key="projects"><NavLink style={({isActive})=>isActive?styleLinkActive:styleLink} to="/projects"><span className="icon"><Icon.BriefcaseFill  /></span><span className="navTitle">Projects</span></NavLink></li>
                    <li key="cert"><NavLink style={({isActive})=>isActive?styleLinkActive:styleLink} to="/certifications"><span className="icon"><FontAwesomeIcon icon={faGraduationCap} /></span><span className="navTitle">Certifications</span></NavLink></li>
                    <li key="art"><NavLink style={({isActive})=>isActive?styleLinkActive:styleLink} to="/articles"><span className="icon"><Icon.PenFill /></span><span className="navTitle">Articles</span></NavLink></li>
                    <li key="ce" style={{'marginTop':'5px'}} ><NavLink style={({isActive})=>isActive?styleLinkActive:styleLink} to="/clinicalExperience"><span className="icon"><FontAwesomeIcon icon={faBrain} /></span><span className="navTitle" style={{'lineHeight':'20px'}}>clinical Experience</span></NavLink></li>
                    <li key="ve" style={{'marginTop':'15px', 'marginBottom':'5px'}}><NavLink style={({isActive})=>isActive?styleLinkActive:styleLink} to="/volunteerExperience"><span className="icon"><FontAwesomeIcon icon={faHandshakeAngle} /></span><span className="navTitle" style={{'lineHeight':'20px'}}>volunteer Experience</span></NavLink></li>
                    <li key="updates"><NavLink style={({isActive})=>isActive?styleLinkActive:styleLink} to="/updates"><span className="icon"><Icon.Newspaper /></span><span className="navTitle">Updates</span></NavLink></li>
                    <li key="about"><NavLink style={({isActive})=>isActive?styleLinkActive:styleLink} to="/about"><span className="icon"><FontAwesomeIcon icon={faUserDoctor} /></span><span className="navTitle">About Me</span></NavLink></li>
                </ul>
            </div>
            <div key="top" className={navActive?"topBar":"topBarWide"}>
            <div key="currloc" style={{'display':'flex', 'gap':'5px'}}>
                {(location.pathname.split("/").filter(Boolean).length === 0 ?["home"]:location.pathname.split("/").filter(Boolean)).map(
                    (it, index)=>(
                    index===0?<div key={index} className="topNav"><Link style={{'color':'inherit', 'textDecoration':'none'}} to={'/'.concat(it)}>{it}</Link></div>:<div className="topNav">{it}</div>))}
                
            </div>
            <div key="mytitle" className="myTitle">                    
                <span className="titleSteth"><FontAwesomeIcon icon={faStethoscope} /></span>Dr.Sri Charan</div>
            </div>
            <div key="main" className={navActive?"main":"mainWide"}>
                    <Outlet />          
            </div>
        </>
    return(
       <>
           {!jumboRendered?Welcome:null}
           {jumboRendered?Main:null}
            
       </>
    )
}
import { useState, useEffect } from "react"
import { Accordion, Spinner, Container, Row, Col } from "react-bootstrap"
import * as Icon from "react-bootstrap-icons"
import { fetchData, accordify } from "./commonFunctions"

export default function Projects({data, setData}){    
    const [loading, setLoading] = useState(true)
    useEffect(()=>{fetchData("projects", data, setData, setLoading)},[data])
    
    
    return(
        <Container fluid>
        {loading?<Spinner />:
        <Row>
            <Col>
            <Accordion>
            {data.map((item, index)=>(accordify(item.featured,item.title, item.html, index)))}
            </Accordion>
            </Col>
        </Row>
        
        }
        </Container>
    )
}
import { useState, useEffect } from "react"
import { Accordion, Spinner, Container, Row, Col } from "react-bootstrap"
import { fetchData, accordify3 } from "./commonFunctions"
export default function VolunteerExperience({data, setData}){
    
    const [loading, setLoading] = useState(true)
    useEffect(()=>{
        fetchData("volunteerExperience", data, setData, setLoading)},[data, setData])
        
    return(
        <Container fluid>
        {loading?<Spinner />:
        <Row>
            <Col>
            <Accordion>
            {data.map((item, index)=>(accordify3(item.title, item.html, index)))}
            </Accordion>
            </Col>
        </Row>
        
        }
        </Container>
    )
}
import { useState, Suspense, lazy} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import NotFound from "./notFound.js";
import { Spinner } from "react-bootstrap";
import Home from './Home.js';
import HomeItems from './homeItems.js';
import Projects from './projects.js';
import Updates from './updates.js';
import Articles from './articles.js';
import Certifications from './certifications.js';
import Dashboard from "./dashboard.js";
import ClinicalExperience from "./clinicalExperience.js";
import VolunteerExperience from "./volunteerExperience.js";
const About = lazy(()=>import("./about.js"));
const Resume = lazy(()=>import ("./resume.js"))
const DbEdit = lazy(()=>import ("./dbedit.js"))
const UpdateDB = lazy(()=>import ("./dbupdate.js"))
const ResUpdate = lazy(()=>import ("./resupdate"))
const Visitors = lazy(()=>import("./visitors"))


export default function Application(){
    const [homeData, setHomeData] = useState()
    const [articleData, setArticleData] = useState()
    const [projectData, setProjectData] = useState()
    const [updateData, setUpdateData] = useState()
    const [certificateData, setCertificateData] = useState()
    const [aboutData, setAboutData] = useState()
    const [clinicalExperienceData, setClinicalExperienceData] = useState()
    const [volunteerExperienceData, setVolunteerExperienceData] = useState()
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />}>   
                    <Route index element={<HomeItems data={homeData} setData={setHomeData} />} /> 
                    <Route path='/home' element={<HomeItems data={homeData} setData={setHomeData} />} />          
                    <Route path="/articles" element={<Articles data={articleData} setData={setArticleData}/>} />
                    <Route path="/projects" element={<Projects data={projectData} setData={setProjectData}/>}/>  
                    <Route path="/updates" element={<Updates data={updateData} setData={setUpdateData}/>}/>
                    <Route path="/certifications" element={<Certifications data={certificateData} setData={setCertificateData} />}/>   
                    <Route path="/dashboard" element={<Dashboard data={homeData} setData={setHomeData} />} />
                    <Route path="/clinicalExperience" element={<ClinicalExperience data={clinicalExperienceData} setData={setClinicalExperienceData} />} />
                    <Route path="/volunteerExperience" element={<VolunteerExperience data={volunteerExperienceData} setData={setVolunteerExperienceData} />} />
                    <Route path="/about" element={<Suspense fallback={<Spinner />}><About data={aboutData} setData={setAboutData} /></Suspense>} />
                    <Route path='/dbupdate' element={<Suspense fallback={<Spinner />}><UpdateDB /></Suspense>} />  
                    <Route path='/dbedit' element={<Suspense fallback={<Spinner />}><DbEdit /></Suspense>} />
                    <Route path='/resume' element={<Suspense fallback={<Spinner />}><Resume /></Suspense>} />
                    <Route path='/resupdate' element={<Suspense fallback={<Spinner />}><ResUpdate /></Suspense>} />
                    <Route path="/visitors" element={<Suspense fallback={<Spinner />}><Visitors /></Suspense>} />
                    <Route path="*" element={<NotFound />} />        
                </Route>
            </Routes>
        </BrowserRouter>
    )
}